<template>
    <div class="my-2">
        <b-row>
            <b-col cols="12" lg="6">
                <EKTable
                    @plus="openDialog"
                    @details="openDialog"
                    @delete-selected="deleteMultiPositions"
                    isPlus
                    :items="positions"
                    :columns="columns"
                >
                </EKTable>
            </b-col>
        </b-row>

        <EkDialog
            ref="positionDialog"
            @delete="deletePosition(positionDto.id)"
            :isEdit="isEdit"
            @ok="setPosition" 
        >
            <template #header>
                <h3>{{ isEdit ? "تعديل المنصب" : "اضافة منصب" }}</h3>
            </template>
            <template #body>
                <ValidationObserver ref="observer">
                    <EKInputText
                        :rules="[
                            {
                                type: 'required',
                                message: 'اسم المنصب مطلوب'
                            }
                        ]"
                        label="اسم المنصب"
                        placeholder="ادخل اسم المنصب"
                        name="name"
                        v-model="positionDto.name"
                    />
                </ValidationObserver>
            </template>
        </EkDialog>
    </div>
</template>

<script>
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import { ValidationObserver } from "vee-validate";
import EKInputText from "@/EK-core/components/EK-forms/EK-input-text";
import EkDialog from "@/EK-core/components/EK-dialog";
import EKTable from "@/EK-core/components/EK-table";
import { mapActions, mapState } from "vuex";
import { nullGuid } from "@/EK-core/util/global";
import { handleDashLangChange } from '@/libs/event-bus';
export default {
    components: {
        EKInputText,
        EkDialog,
        ValidationObserver,
        EKTable
    },
    data: () => ({
        columns: [
            {
                label: "الاسم",
                field: "name"
            },
            {
                label: "تفاصيل",
                field: "details",
                sortable: false
            }
        ]
    }),
    setup() {
        const { t } = useI18nUtils();
        return { t };
    },
    mounted() {
        this.getPositions();
         handleDashLangChange(this.getPositions);
    },
    computed: {
        ...mapState({
            positions: state => state.settings.positions,
            positionDto: state => state.settings.positionDto
        }),
        isEdit() {
            return this.positionDto.id != nullGuid;
        }
    },
    methods: {
        ...mapActions([
            "getPositions",
            "addPosition",
            "updatePosition",
            "deleteMultiPositions",
            "deletePosition"
        ]),
        openDialog(data) {
            if (data) {
                this.$store.commit("SET_POSITION_DTO", {
                    name: data.row.name,
                    id: data.row.id,
                    languages: data.row.languages
                });
                this.$refs.positionDialog.open();
            } else {
                this.$store.commit("SET_POSITION_DTO");
                this.$refs.positionDialog.open();
            }
        },
        setPosition() {
            this.$refs.observer.validate().then(success => {
                if (success) {
                    this.isEdit
                        ? this.updatePosition(this.positionDto)
                        : this.addPosition(this.positionDto);
                    this.$refs.positionDialog.close();
                    this.$refs.observer.reset();
                }
            });
        }
    }
};
</script>

<style></style>
